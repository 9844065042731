
import patientAccountService from "../../../core/services/patientAcountService";
import { 
    SendPasswordRecoveryCodeToEmailRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const SendPasswordRecoveryCodeToEmail = async (email) => {
    try {
        const req = new SendPasswordRecoveryCodeToEmailRequest();
        req.setEmail(email);

        const res = await patientAccountService.sendPasswordRecoveryCodeToEmail(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export {
    SendPasswordRecoveryCodeToEmail,
}